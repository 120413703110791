import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { Booking } from "../../models/booking.model";
import { Service, TimeSlotDurationOptions } from "../../models/service.model";
import { StylistService } from "../../services/stylist.service";
import { UserService } from "../../services/user.service";
import { Stylist } from "../../models/stylist.model";

@Component({
  selector: "sm-booking-summary-list",
  templateUrl: "./booking-summary-list.component.html",
  styleUrls: ["./booking-summary-list.component.css"],
})
export class BookingSummaryListComponent implements OnInit {
  @Input() booking: Booking;

  @Output() serviceDelete = new EventEmitter<Service>();

  public formattedDate: string;
  public selectedStylist: Stylist;
  public filteredStylists: Stylist[];

  constructor(
    private stylistService: StylistService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {}
  async ngOnInit() {
    console.log("Booking:", this.booking);
    const originalDate = new Date(this.booking.getStartDateTime());

    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    await this._filterStylists();
    this.formattedDate = originalDate.toLocaleDateString("en-US", options);
    if (this.isSingleItem() == true) {
      // if there is only one stylist available, set them as selected
      this.selectedStylist = this.filteredStylists[0];
      this.onCheckboxChange(this.selectedStylist);
    }
  }

  public removeService(service: Service) {
    this.serviceDelete.emit(service);
  }
  public onCheckboxChange(stylist: Stylist) {
    const TimeSlotDurationOptions: TimeSlotDurationOptions = {
      startDuration: stylist.getSelectedDuration(),
      processDuration: stylist.getSelectedProcessingDuration(),
      finishDuration: stylist.getSelectedFinishedDuration(),
    };
    this.selectedStylist = stylist;
    this.booking.getServices()[0]?.getServiceDefinition().setStylist(stylist);
    this.booking.getServices()[0]?.setStylist(stylist);

    this.booking
      .getServices()[0]
      ?.getServiceDefinition()
      .setID(stylist.getSelectedServiceID());
    this.booking.getServices()[0]?.setDurations(TimeSlotDurationOptions);
    this.booking.getServices()[0]?.setPrice(stylist.getSelectedPrice());
  }
  public isSingleItem(): boolean {
    return this.filteredStylists.length === 1;
  }
  private async _filterStylists() {
    console.log("_filterStylists called");
    this.filteredStylists = await this.booking
      .getServices()
      .reduce(async (acc: Promise<Stylist[]>, service) => {
        const accumulatedStylists = await acc;
        const commonStylists = Array.from(
          service.getServiceDefinition().getCommonStylists()
        );
        return [...accumulatedStylists, ...commonStylists];
      }, Promise.resolve([]))
      .then((stylists) =>
        stylists.filter((stylist) =>
          this.booking.getAvailableStylistIDs().includes(stylist.getID())
        )
      );
    console.log("Filtered stylists:", this.filteredStylists);
    this.cdr.detectChanges();
  }
}
