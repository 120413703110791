import { Stylist } from "./stylist.model";
import { Cloneable } from "./cloneable";

export interface ServiceDefinitionOptions {
  id: number;
  parentID: number;
  categoryID: number;
  serviceName: string;
  duration: number;
  processDuration: number;
  finishDuration: number;
  price: number;
  clientBookable: boolean;
  stylist: Stylist;
  description: string;
  startingAt: number;
  commonStylists: Set<Stylist>;
}

export class ServiceDefinition implements Cloneable<ServiceDefinition> {
  private id: number;

  private serviceName: string;

  private duration: number;

  private processDuration: number;

  private finishDuration: number;

  private price: number;

  private clientBookable: boolean;

  private stylist: Stylist;

  private parentID: number;

  private categoryID: number;

  private description: string;

  private startingAt: number;
  private commonStylists: Set<Stylist>;

  constructor(options: ServiceDefinitionOptions) {
    this.id = options.id;
    this.serviceName = options.serviceName;
    this.duration = options.duration;
    this.processDuration = options.processDuration;
    this.finishDuration = options.finishDuration;
    this.price = options.price;
    this.clientBookable = options.clientBookable;
    this.stylist = options.stylist;
    this.parentID = options.parentID;
    this.categoryID = options.categoryID;
    this.description = options.description;
    this.startingAt = options.startingAt;
    this.commonStylists = options.commonStylists;
  }

  public clone(): ServiceDefinition {
    return new ServiceDefinition({
      id: this.getID(),
      serviceName: this.getServiceName(),
      duration: this.getDuration(),
      processDuration: this.getProcessDuration(),
      finishDuration: this.getFinishDuration(),
      price: this.getPrice(),
      clientBookable: this.isClientBookable(),
      stylist: this.getStylist() ? this.getStylist().clone() : undefined,
      parentID: this.getParentID(),
      categoryID: this.getCategoryID(),
      description: this.getDescription(),
      startingAt: this.getStartingAt(),
      commonStylists: this.getCommonStylists(),
    });
  }

  public static parseServiceDefinition(data: Object): ServiceDefinition {
    return new ServiceDefinition({
      id: data["id"],
      serviceName: data["name"],
      duration: data["duration"],
      processDuration: data["processDuration"],
      finishDuration: data["finishDuration"],
      price: data["price"],
      commonStylists: data["commonStylists"],
      clientBookable: data["clientBookable"] === 1 ? true : false,
      stylist: new Stylist({
        id: data["stylistID"],
        firstName: data["stylistFirstName"],
        lastName: data["stylistLastName"],
        photoUrl: data["stylistPhotoUrl"],
        bookingInterval: data["stylistBookingInterval"],
      }),
      parentID: data["parentID"],
      categoryID: data["categoryID"],
      description: data["description"],
      startingAt: data["startingAt"],
    });
  }

  public getID(): number {
    return this.id;
  }

  public setID(id: number) {
    this.id = id;
  }

  public getServiceName(): string {
    return this.serviceName;
  }

  public setServiceName(serviceName: string): void {
    this.serviceName = serviceName;
  }

  public getDuration(): number {
    return this.duration;
  }

  public setDuration(duration: number) {
    this.duration = duration;
  }

  public getProcessDuration(): number {
    return this.processDuration ?? 0;
  }

  public setProcessDuration(processDuration: number) {
    this.processDuration = processDuration;
  }

  public getFinishDuration(): number {
    return this.finishDuration ?? 0;
  }

  public setFinishDuration(finishDuration: number) {
    this.finishDuration = finishDuration;
  }

  public getPrice(): number {
    return this.price;
  }

  public setPrice(price: number) {
    this.price = price;
  }
  public getCommonStylists() {
    return this.commonStylists;
  }
  public setCommonStylists(commonStylists: Set<Stylist>) {
    this.commonStylists = commonStylists;
  }
  public addToCommonStylists(commonStylist: Stylist) {
    this.commonStylists.add(commonStylist);
  }
  public isClientBookable(): boolean {
    return this.clientBookable;
  }

  public setClientBookable(clientBookable: boolean) {
    this.clientBookable = clientBookable;
  }

  public getStylist(): Stylist {
    return this.stylist;
  }

  public setStylist(stylist: Stylist) {
    this.stylist = stylist;
  }

  public getParentID(): number {
    return this.parentID;
  }

  public setParentID(parentID: number) {
    this.parentID = parentID;
  }

  public getCategoryID(): number {
    return this.categoryID;
  }

  public setCategoryID(categoryID: number) {
    this.categoryID = categoryID;
  }

  public isBookBack(): boolean {
    return this.processDuration !== 0 && this.finishDuration !== 0;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getStartingAt(): number {
    return this.startingAt;
  }

  public setStartingAt(startingAt: number) {
    this.startingAt = startingAt;
  }

  public toJSON(): Object {
    return {
      id: this.getID(),
      parentID: this.getParentID(),
      categoryID: this.getCategoryID(),
      name: this.getServiceName(),
      duration: this.getDuration(),
      processDuration: this.getProcessDuration(),
      finishDuration: this.getFinishDuration(),
      price: this.getPrice(),
      clientBookable: this.isClientBookable() ? 1 : 0,
      stylistID: this.getStylist() ? this.getStylist().getID() : undefined,
      description: this.getDescription(),
      startingAt: this.getStartingAt(),
    };
  }
}
