<div class="login-container">
  <form (ngSubmit)="onSaveNote()">
    <h2>Add a note</h2>
    <textarea
      matInput
      placeholder="Your note ..."
      type="text"
      [rows]="5"
      [(ngModel)]="noteText"
    ></textarea>
    <div class="add-note-button-wrapper">
      <button mat-raised-button (click)="onClose()">Close</button>
      <button mat-raised-button type="submit">Save</button>
    </div>
  </form>
</div>
