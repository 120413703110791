import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-add-note",
  templateUrl: "./add-note.component.html",
  styleUrls: ["./add-note.component.scss"],
})
export class AddNoteComponent implements OnInit {
  public noteText: string = "";

  constructor(private dialogRef: MatDialogRef<AddNoteComponent>) {}

  ngOnInit(): void {
    // Load the saved note when the component initializes
    this.noteText = localStorage.getItem("savedNote") || "";
  }

  public onSaveNote(): void {
    // Save the note to local storage before closing the dialog
    localStorage.setItem("savedNote", this.noteText);
    this.dialogRef.close(this.noteText);
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
