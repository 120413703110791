<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>
<div *ngIf="!isLoading">
  <sm-booking-details
    *ngIf="isAnyBooking != undefined && booking"
    [booking]="booking"
    [title]="title"
    [fromSummary]="true"
    [anyBooking]="isAnyBooking"
    [subTitle]="subTitle"
    (photoUpdate)="onPhotoUpdate($event)"
    showDeleteButton="false"
  ></sm-booking-details>

  <div>
    <salonmonster-stripe-payment
      *ngIf="clientBookingPayments"
      (onBookingSave)="onBookingSave()"
      [refreshStripe]="refreshStripe"
      [booking]="booking"
      [(savingStripe)]="savingStripe"
      [errorOccurredWhileSaving]="enableBookButton"
    ></salonmonster-stripe-payment>
  </div>
  <div
    sm-center
    class="margin-top-20"
    *ngIf="!clientBookingPayments || !this.userService.isLoggedIn()"
  >
    <button
      mat-raised-button
      class="smc-button-large smc-button-primary"
      [disabled]="!enableBookButton"
      (click)="onBookingSave()"
    >
      {{
        this.userService.isLoggedIn()
          ? "Book it"
          : clientBookingPayments
          ? "Log in to book"
          : "Log in and book it"
      }}
    </button>
  </div>

  <br />
  <br />
  <br />
  <br />
</div>
